<template>
  <b-overlay
    :show="spinner"
    spinner-variant="primary"
  >
    <salidasForm
      :salida="salidaData"
      salidaSubmit="Crear Salida"
      @processSalida="agregarSalidas"
    />
  </b-overlay>
</template>

<script>

import { BOverlay } from 'bootstrap-vue'
import { mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const salidasForm = () => import('@/layouts/components/Salidas/SalidasForm.vue')

export default {
  components: {
    BOverlay,
    salidasForm,
  },
  data() {
    return {
      spinner: false,
      salidaData: {
        correlativo: '',
        fecha: new Date(),
        referencia: '',
        valorTotal: 0,
        idCliente: null,
        idUsuario: null,
        estado: null,
        salidasProductos: [],
      },
    }
  },
  watch: {
    getUserAuth() {
      this.idUsuario = this.getUserAuth.id
    },
  },
  methods: {
    ...mapActions({ createSalidas: 'salidas/addSalidas' }),
    agregarSalidas(salida) {
      this.spinner = true
      this.createSalidas(salida).then(() => {
        const errorSalidas = store.state.salidas
        const errorMessage = errorSalidas.errorMessage.errors
        if (!errorSalidas.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Salida creada con éxito 👍',
              icon: 'CheckIcon',
              text: `La salida "${salida.referencia}" fue creada con éxito!`,
              variant: 'success',
            },
          },
          {
            timeout: 4000,
          })
          this.$router.replace({
            name: 'salidas',
          })
        } else if (errorMessage.rut) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.rut[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.spinner = false
      })
    },
  },
}
</script>
